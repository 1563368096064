﻿sticky {
    display: block;

    .spacer {
        display: none;
    }

    .sticking {
        transition: top .3s;
        position: fixed;
        z-index: 4;

        + .spacer {
            display: block;
        }

        &.released {
            position: absolute;
            bottom: 0;
            top: auto !important;
            left: 0 !important;
        }

        > .content {
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    &:not(.sticking) {
        /*padding-right: 0 !important;*/
    }
}

[sticky-container] {
    position: relative;
    sticky {
        overflow: hidden;
    }
}
