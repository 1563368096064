﻿.widget-document-search {
    .search {
        margin-bottom: 1.5rem;
    }

    .divider {
        color: $yellow;
        margin: 2.5rem 0;
    }

    h5 {
        margin-bottom: 1.25rem;
    }

    .brands {
        margin-bottom: 2.5rem;
    }

    .search, select {
        width: 340px;
        max-width: 100%;
    }
}