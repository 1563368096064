﻿#header {
    .header-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        background-color: $black;
    }

    .grid-container {
        display: flex;
    }

    a {
        &:not(.button) {
            color: $white;
            text-transform: uppercase;

            &:hover {
                color: $yellow;
            }
        }
    }

    .secondary-links {
        a {
            font-size: .875rem;
        }
    }

    .logo {
        a {
            display: inline-block;
        }
    }

    .secondary-navigation {
        display: none;
    }

    .main-navigation {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .dropdown {
        display: none;
    }

    .social-links {
        i {
            margin-right: .5rem;
        }

        .icon-link {
            display: flex;
        }
    }

    #language-toggle {
        font-size: 2.5rem;
        margin-right: .75rem;
        width: 2.5rem;
        height: 2.5rem;
        display: inline-block;
        margin-right: 1rem;
        color: $yellow;
    }

    .rte {
        margin-top: 3rem;
    }

    @include breakpoint(medium down) {
        height: 70px;

        .main-navigation {
            justify-content: space-between;
            align-items: center;
        }

        .nav-container {
            display: flex;
            align-items: center;
        }

        .toggle-icon {
            font-size: 2rem;
        }

        .mobile-overlay, .mobile-panel {
            position: absolute;
            top: 100%;
            height: calc(100vh - 100%);
            opacity: 0;
            pointer-events: none;
            transition: all .5s;
        }

        .mobile-panel {
            right: 0;
            transform: translateX(300px);
            width: 300px;
            background-color: $black;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            position: fixed;
            height: calc(100% - 70px);
            top: 70px;
            z-index: 4;
        }

        @at-root .menu-open #header {
            .mobile-overlay, .mobile-panel-navigation {
                opacity: 1;
                pointer-events: initial;
            }

            .mobile-panel-navigation {
                transform: translateX(0);
            }
        }

        @at-root .languages-open #header {
            #language-toggle {
                color: $white;
            }

            .mobile-overlay, .mobile-panel-languages {
                opacity: 1;
                pointer-events: initial;
            }

            .mobile-panel-languages {
                transform: translateX(0);
            }
        }

        .mobile-panel-languages {
            text-align: right;

            li {
                padding-right: 3.25rem;
            }

            li + li {
                margin-top: 1rem;
            }

            .active {
                color: $yellow;
            }
        }

        .mobile-overlay {
            left: 0;
            z-index: 2;
            background-color: rgba($black, .5);
            width: 100%;
        }

        .search {
            width: 100%;
        }

        .search, .social-links {
            flex: 0 0 auto;
        }

        .search, .link-container {
            margin-bottom: 1rem;
        }

        .social-links {
            justify-content: center;
            font-size: 2rem;
            margin: 0 -.75rem;

            li {
                margin: 0 .75rem;
            }
        }

        .link-container {
            flex: 1 1 auto;
        }

        .shifter-group {
            li + li {
                margin-top: 1rem;
            }
        }

        .shifter {
            .secondary-links {
                padding-top: 1rem;
                border-top: 1px solid $yellow;
                margin-top: 1rem;

                a {
                    font-size: 1rem;
                }
            }
        }
    }

    @include breakpoint(large) {
        height: 161px;

        .secondary-navigation {
            border-bottom: 2px solid $white;
            display: block;

            .grid-container {
                justify-content: flex-end;
                align-items: center;
            }
        }

        .secondary-links {
            margin-right: 1rem;

            a {
                letter-spacing: 2px;
                font-weight: 600;
            }
        }

        .social-links {
            margin-right: 1rem;
        }

        .main-navigation {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        .main-links {
            margin-top: .75rem;

            a {
                font-weight: 600;
                letter-spacing: 2px;
            }
        }

        .nav-container {
            margin-left: 6rem;
        }

        .search {
            z-index: 2;
        }

        .dropdown {
            max-height: 70vh;
            overflow: auto;
        }

        .aligned {
            position: relative;

            .dropdown {
                min-width: 282px;
                padding: 1rem;
                left: -1rem;
                background-color: $black;
            }

            ul {
                margin: 0;
            }

            li {
                + li {
                    margin-top: 1rem;
                }
            }
        }

        .wide-content {
            .dropdown {
                width: 100%;
                padding: 0;
                left: 0;
            }
        }

        .rte {
            margin-top: 0;
            > * {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .descendants, .brands {
            padding-bottom: 1.5rem;
        }

        .descendants {
            flex: 1 1 auto;
        }

        .link-wrapper {
            padding-right: 3rem;
            margin-right: 3rem;
            border-right: 1px solid $white;
        }

        .brands {
            flex: 0 0 350px;

            .cell {
                margin-bottom: 1.5rem;
            }

            .nav-block {
                margin-bottom: -1.5rem;
            }
        }

        .dropdown {
            position: absolute;
            top: 100%;
            background-color: $black;
            z-index: 1;

            ul {
                list-style: none;
            }

            h6 {
                &, a {
                    color: $yellow;
                }
            }
        }

        .dropdown-trigger {
            &:hover {
                .dropdown {
                    display: block;
                }

                &.wide-content {
                    .dropdown {
                        display: flex;
                    }
                }
            }

            > a {
                &::after {
                    margin-top: .25rem;
                    display: block;
                    content: '';
                    width: 0;
                    height: .25rem;
                    transition: width .3s;
                    background-color: $yellow;
                }
            }

            > .spacer {
                position: relative;
                height: 0;
                z-index: 1;

                &::after {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    display: block;
                    content: '';
                    height: 2rem;
                }
            }

            &:hover > a, > .active {
                color: $yellow;

                &::after {
                    width: 100%;
                }
            }
        }

        .nav-block + .nav-block {
            margin-top: 1rem;
        }

        .inner-links {
            > li {
                + li {
                    margin-top: 1rem;
                }
            }
        }

        .rte {
            font-size: 1.25rem;
            font-weight: 700;
        }

        .mobile-panel {
            display: none;
        }

        .language-block {
            margin-left: 1.5rem;
            position: relative;
            text-transform: uppercase;
            display: flex;
            flex-wrap: nowrap;
            border-left: 1px solid $white;
            font-weight: bold;

            .current-language {
                display: flex;
                justify-content: center;
                align-items: center;
                transition: background-color .3s, color .3s;
                color: $black;
                background-color: $white;
                width: 132px;
                height: 47px;
                font-size: 1.125rem;

                &::after {
                    content: '';
                    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="8" height="5" viewBox="0 0 16 10"><polygon points="0,0 16,0 8,10" style="fill: rgb%280, 0, 0%29"></polygon></svg>');
                    display: block;
                    width: 8px;
                    height: 5px;
                    margin-left: 1rem;
                }
            }

            .menu-icon {
                font-size: 2.5rem;
                margin-right: .75rem;
                width: 2.5rem;
                height: 2.5rem;
            }

            .dropdown {
                position: absolute;
                border-top: 2px solid $white;
                right: 0;
                width: 100%;
                top: 100%;
                transition: visibility .3s, opacity .3s;
                background-color: $offWhite;

                a {
                    font-size: 1.125rem;
                    display: block;
                    color: $black;
                    padding: .75rem 2.5rem .75rem .75rem;
                    text-align: right;
                    transition: background-color .3s, color .3s;

                    &:hover, &:focus {
                        background-color: $grey;
                        color: $yellow;
                    }
                }
            }

            .active {
                background-color: $grey-light;
            }

            &:hover, &:focus {
                cursor: pointer;

                .current-language {
                    background-color: $offWhite;
                }

                .dropdown {
                    display: block;
                }
            }
        }
    }
}
