﻿&.variant-video {
    @include breakpoint(large down) {
        .feed-item {
            .video {
                .overlay-text {
                    padding: 1rem 1.5rem;
                }

                h4 {
                    font-size: 1.5rem;
                    line-height: 1.75rem;
                    margin-bottom: .5rem;
                }

                h5 {
                    font-size: 1.125rem;

                    i {
                        font-size: 1.5rem;
                        margin-right: 1rem;
                        top: -1px;
                    }
                }
            }
        }
    }
}
