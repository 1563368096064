﻿.revealer {
    display: inline-block;
    color: $yellow;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    &:hover {
        color: lighten($yellow, 10%);
    }
}

.reveal-container {
    overflow: hidden;
}

.reveal-target {
    display: none;
}