﻿.optanon-show-settings {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: auto;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    line-height: 1.625rem;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    padding: .875rem 1.5rem .8125rem;
    height: auto !important;
    border-width: 0;
    float: none;
    background-color: $yellow !important;
    transition: background-color .3s;
    color: $black !important;

    &:hover {
        cursor: pointer;
        background-color: lighten($yellow, 10%) !important;
    }

    &.show {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
    }

    .bmg-icon {
        font-family: Legends;
        font-size: 1.5rem;
        vertical-align: middle;
        margin-right: .5rem;
    }
}
