﻿.suggestor {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $white;
    width: 100%;
    height: 0;
    overflow: hidden;
    width: 368px;

    h6, li, .warning {
        color: $grey;
    }

    h6 {
        font-size: .75rem;
        padding: 2rem 2rem 0;
        margin-bottom: 1rem;
    }

    ul {
        margin: 0;
        list-style-type: none;
    }

    li {
        padding: .75rem 2rem;
        background-color: transparent;
        transition: background-color .3s;
        &:hover {
            cursor: pointer;
            background-color: lighten($yellow, 10%);
        }
    }

    .warning {
        display: none;
        padding: 1rem 2rem 2rem;
        margin-bottom: 0;
    }
}
