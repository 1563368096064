﻿&.accordion-default {

    .accordion-item:not(.is-active) > .accordion-title {
        border-bottom: 1px solid $white;
    }

    .accordion-title {
        padding-bottom: .5rem;
        border-bottom: 1px solid $white;
        font-size: 1.125rem;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;

        &:hover {
            color: $white;
        }

        &::before {
            font-size: 2rem;
            right: 0;
            margin-top: -1.375rem;
            color: $white;
        }
    }

    .accordion-content {
        padding-top: 1.5rem;
    }
}
