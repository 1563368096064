﻿$filterWidth: rem-calc(258);
&.layout-side-filters {
    h5 {
        font-size: 1.125rem;
    }

    .filter {
        h5 {
            color: $yellow;
        }
        + .filter {
            margin-top: 3.5rem;
        }
    }

    .type-search, .type-location {
        + .type-search, + .type-location {
            margin-top: 1.5rem;
        }
    }

    multi-checkbox {
        display: block;
    }

    .accordion-title {
        &, &:hover {
            color: $yellow;
        }
    }

    @include breakpoint(large) {
        .feed {
            position: relative;
            display: flex;
            justify-content: flex-end;
        }

        .filters {
            flex: 0 0 $filterWidth;
        }

        pagination {
            display: block;
            flex: 0 1 991px;
            max-width: 991px;
            padding-left: 5.5rem;
            margin-left: 3.5rem;
            border-left: 1px solid $white;
        }
    }

    @media print, screen and (min-width: #{$grid-container + 1rem}) {
        .feed {
            left: calc(38rem - 50vw);
            width: calc(50vw + 35rem);
        }
    }
}