﻿.search {
    width: 258px;
    background-color: $yellow;
    display: flex;
    height: 47px;
    position: relative;
    align-items: center;
    max-width: 100%;

    input, button {
        color: $black;
        margin: 0;
    }

    input, button, input:focus {
        background-color: transparent;
        border-width: 0;
        box-shadow: none;
    }

    input::placeholder {
        color: inherit;
    }

    input {
        text-transform: uppercase;
        font-size: .875rem;
        font-weight: 600;
        flex: 1 1 auto;
        padding-left: 1.25rem;
        letter-spacing: 2px;
    }

    button {
        flex: 0 0 60px;
        font-size: 1.125rem;
        transition: background-color .3s;
        height: 100%;

        &:hover {
            cursor: pointer;
            background-color: rgba($white, .2);
        }
    }

    button.no-button-style {
        flex-basis: 15px;
        border: 0;
        box-shadow: none;
        position: relative;
        padding: 0 5px;
        background: inherit;
        right: 4px;
        z-index: 1;

        &:hover {
            background: inherit !important;
            cursor: pointer;
        }

        i.fa-times {
            font-size: 12px;
            display: block;

            &:hover {
                cursor: pointer;
            }
        }
    }



    .fa-pin {
        font-size: 1.5rem;
    }

    &.expandable {
        width: 155px;
        justify-content: flex-end;

        input {
            background-color: inherit;
            position: absolute;
            width: 95px;
            right: 60px;
            top: 0;
            height: 100%;
            transition: width .6s;

            &:focus {
                width: 308px;
            }
        }
    }

    &.expandable {
        .input-focus:hover + input[type='text'] {
            width: 308px;
        }
    }

    &.light {
        background-color: $white;

        button {
            &:hover {
                background-color: rgba($black, .1);
            }
        }
    }
}
