﻿.umbraco-forms-multi-step-modal {
    .umbraco-forms-navigation {
        > div {
            display: flex;
            flex-wrap: wrap;
        }

        .button--next {
            order: 1;
        }

        @include breakpoint(small only) {

            .button {
                min-width: auto;
                flex: 1 1 auto;
                max-width: 200px;
            }
        }
    }
}
