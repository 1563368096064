﻿select {
    height: 47px;
    text-transform: uppercase;
    width: 258px;
    max-width: 100%;
    padding: 0 2.5rem 0 1.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: .875rem;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 8 6'><polygon points='7.06666667 0.45 4 3.9 0.933333333 0.45 0 1.5 4 6 8 1.5' style='fill: rgb%280, 0, 0%29'></polygon></svg>");
    background-position: right -1.25rem center;
    &, &:focus {
        background-color: $white;
    }

    &, option {
        letter-spacing: 2px;
        font-weight: 600;
    }

    &.dark {
        color: $white;
        border: 1px solid $white;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 8 6'><polygon points='7.06666667 0.45 4 3.9 0.933333333 0.45 0 1.5 4 6 8 1.5' style='fill: rgb%28255, 255, 255%29'></polygon></svg>");

        &, &:focus {
            background-color: $black;
        }
    }
}

input[type="text"], textarea {
    font-size: .875rem;
}

@import 'fields/*.scss';

@import '_customRadio.scss';
@import '_search.scss';
@import '_checkboxDropdown.scss';
@import '_umbracoForms.scss';
@import '_multiStepModal.scss';
@import '_warrantyRegistration.scss';