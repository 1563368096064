﻿@import 'foundation-sites/scss/util/_unit.scss';

/*-------------BACKGROUND COLOR------------*/
$yellow: #ECBC2F;

/*----COLORS----*/
$white:     #fff;
$offWhite:   #F4F4F4;
$black:     #000;
$dark-green: #19A79A;
$green: #138046;
$light-green:   #6DBDBE;
$blue: #023866;
$red:       #EE4056;
$dark-red:  #BF0057;
$darker-red: #822F43;
$grey-lighter: #DDDDDD;
$grey-light: #A6ACB4;
$grey: #606B75;
$grey-darker: #313334;
$yellow: #ECBC2F;
$orange:    #F97253;
$body-font-color: $white;
$form-label-color: $white;

/*----FONTS----*/
$barlow: 'Barlow', sans-serif;
$barlowCondensed: 'Barlow Condensed', sans-serif;
$poppins: 'Poppins', sans-serif;
$iconFont: 'Legends';
$body-font-family: $barlow;

/*----ANIMATION----*/
$defaultAnimationSpeed: .3s;

/*----GRID----*/
$grid-container: 75rem;
$grid-margin-gutters: ( small: 20px, medium: 32px );
$margins: (1: 1rem, 2: 2.5rem, 3: 4.5rem, 4: 6rem);
$paddings: (1: 1rem, 2: 2.5rem, 3: 6rem, 4: 9rem);
$smallGutters: rem-calc(map-get($grid-margin-gutters, small) * .5);
$mediumGutters: rem-calc(map-get($grid-margin-gutters, medium) * .5);
$headerInitialHeight: 136px;
$headerScrolledHeight: 111px;

/*----HEADER----*/
$toggleIconColor: $yellow;
$toggleIconActiveColor: $white;
$mobileHeaderHeight: 70px;
$desktopHeaderHeight: 161px;

/*----ACCORDION DEFAULTS----*/
$accordion-background: transparent;
$accordion-item-color: $white;
$accordion-item-background-hover: transparent;
$accordion-content-background: transparent;
$accordion-content-border: none;
$accordion-item-padding: 0;
$accordion-content-padding: 0;