.widget-feed {
    .feed-modal {
        .slide-background {
            padding: 1rem;
        }

        .slide-wrapper {
            text-align: right;
            overflow-y: auto;
            background-color: $white;
            padding: 1rem 2.5rem 2.5rem;

            .close-button {
                position: relative;
                display: inline-block;
                margin-bottom: 1rem;
                right: 0;
                top: 0;
            }
        }

        .video {
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba($blue, .2);
                transition: background-color .3s;

                &, span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                span {
                    border-radius: 50%;
                    border: 4px solid $red;
                    background-color: transparent;
                    transition: background-color .3s, color .3s;
                    color: $red;
                    width: 10rem;
                    height: 10rem;
                    font-size: 4rem;

                    &::before {
                        position: relative;
                        left: .125em;
                    }
                }

                &:hover {
                    background-color: rgba($blue, .3);

                    span {
                        background-color: $red;
                        color: $white;
                    }
                }
            }

            .loaded + .overlay {
                display: none;
            }
        }
    }

    .feed-list {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem - $smallGutters * 2;
    }

    .feed-item {
        margin-bottom: $smallGutters * 2;
    }

    .nav-block {
        white-space: nowrap;
    }

    .reset {
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.5px;
        display: inline-block !important;

        &:hover {
            color: $yellow;
        }

        &.ng-hide {
            visibility: hidden;
            pointer-events: none;
        }
    }

    .pagination-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        h5 {
            margin-bottom: 0;
        }

        select {
            margin: 0 1.5rem 0 0;
            padding-right: 1.75rem;
            width: 70px;
            color: $yellow;
            height: 30px;

            &, &:focus {
                background-color: $black;
            }
        }

        a {
            color: $white;

            &:hover {
                color: $yellow;
            }
        }

        .select-block, a {
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1.5px;
        }

        .select-block {
            margin: 0 2.5rem;
            display: inline-flex;
            align-items: center;
        }

        .ng-hide + .select-block {
            margin: 0;
        }

        .disabled {
            visibility: hidden;
            pointer-events: none;
        }
    }

    .lead-in, .pagination-controls {
        h5 {
            font-size: 1.125rem;
        }
    }

    .mobile-filter-toggles {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .75rem;
    }

    .feed-links {
        flex: 0 0 220px;

        h5 {
            color: $yellow;
        }

        .icon-link {
            i {
                width: 50px;
                font-size: 2.375rem;
                margin-right: .75rem;
                color: $white;
                transition: color .3s;
            }

            &:hover, &:focus {
                i {
                    color: $yellow;
                }
            }
        }

        li + li {
            margin-top: 1rem;
        }
    }

    .circle-button {
        position: absolute;
        top: 50vh !important;
        margin-top: -35px;

        &--previous {
            left: 1.5rem;
        }

        &--next {
            right: 1.5rem;
        }
    }

    &.layout-feed {
        .search {
            width: 340px;
        }

        .lead-in {
            h5 {
                color: $yellow;
                margin-bottom: 1rem;
            }
        }

        .filter-block {
            margin-bottom: -2.5rem;
        }

        .filter {
            margin-bottom: 2.5rem;

            select {
                margin-bottom: 0;
            }
        }
    }

    @include breakpoint(medium down) {

        .pagination-controls {
            h5 {
                margin-bottom: 1rem;
            }

            .select-block {
                margin: 0 1.5rem;
            }
        }

        .filters, .filter-background {
            position: fixed;
            top: 70px;
            height: calc(100vh - 70px);
        }

        .close, .open {
            color: $white;

            &:hover {
                color: $yellow;
            }
        }

        .open {
            font-size: 1.25rem;
            display: inline-block;
            text-transform: uppercase;

            i {
                margin-right: .5rem;
            }
        }

        .close {
            position: absolute;
            top: .5rem;
            right: $smallGutters;

            + .filter-block {
                padding-top: .375rem;
            }
        }

        .filters {
            width: rem-calc(258);
            right: 0;
            transform: translateX(258px);
            transition: transform .5s;
            z-index: 4;
            background-color: $black;
            display: flex;
            flex-direction: column;

            > h5 {
                padding: .5rem 2.5rem 0 0;
                margin: 0 $smallGutters;
            }
        }

        .filter-block {
            flex: 1 1 auto;
            overflow: auto;
            padding: 1rem $smallGutters;

            &:first-child {
            }
        }

        .filter-background {
            background-color: rgba($black, .5);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            z-index: 3;
            transition: .5s all;

            &:hover {
                cursor: pointer;
            }
        }

        .filters-open {
            .filters {
                transform: translateX(0);
            }

            .filter-background {
                visibility: visible;
                opacity: 1;
                pointer-events: inherit;
            }
        }
    }

    @include breakpoint(medium) {
        .feed-list {
            margin-bottom: 2.5rem - $mediumGutters * 2;
        }

        .feed-item {
            margin-bottom: $mediumGutters * 2;
        }

        &.layout-feed {

            .filters {
                margin-bottom: 3.5rem;
            }

            multi-checkbox {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -#{$mediumGutters} -1rem;

                > div {
                    margin: 0 #{$mediumGutters} 1rem;
                }

                label {
                    white-space: nowrap;
                }
            }
        }
    }

    @include breakpoint(large) {
        .lead-in {
            display: flex;
            justify-content: space-between;
            margin: 0 -1rem;

            > div {
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }

        .reset {
            margin-top: 1rem;
        }

        &.layout-feed {

            .feed-links {
                flex: 0 0 auto;
            }

            .filter-block {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -2.5rem -2.5rem;
            }

            .filter {
                margin: 0 2.5rem 2.5rem;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: flex-end;

                select {
                    margin-bottom: 0;
                }
            }

            .type-checkbox {
                flex: 0 0 calc(100% - 5rem);
            }
        }
    }

    @import '_shop.scss';
    @import '_news.scss';
    @import '_video.scss';
}
@import '_dealers.scss';