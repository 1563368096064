﻿checkbox-dropdown {
    display: block;
    width: 258px;
    max-width: 100%;

    a {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    .clear-container {
        text-align: right;
        margin-top: .5rem;
    }

    .clearer {
        &.hidden {
            visibility: hidden;
        }

        &:hover {
            color: $yellow;
        }
    }

    .dropdown {
        position: relative;
    }

    .placeholder {
        position: relative;
        transition: none;
        border: 1px solid $white;
        background-color: $black;
        display: block;
        height: 47px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0 2.5rem 0 1.25rem;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: .875rem;
        width: 100%;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 8 6'><polygon points='7.06666667 0.45 4 3.9 0.933333333 0.45 0 1.5 4 6 8 1.5' style='fill: rgb%28255, 255, 255%29'></polygon></svg>");
        background-position: right 1.25rem center;
        background-size: 9px 6px;
        background-repeat: no-repeat;

        span {
            display: block;
            position: absolute;
            width: calc(100% - 3.75rem);
            white-space: nowrap;
            text-overflow: ellipsis;
            left: 1.25rem;
            top: .75rem;
            overflow: hidden;
            letter-spacing: 2px;
        }

        + .options {
            padding: .5rem 1.25rem;
            z-index: 2;
            background-color: $black;
            border: 1px solid $white;
            border-top-width: 0;
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 300px;
            overflow: auto;
        }

        &.active {
            + .options {
                display: block;
            }
        }
    }

    .custom-radio {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &[disabled] {
        opacity: .7;

        a {
            cursor: not-allowed;
        }
    }
}

.checkbox-dropdown-options {
    background-color: $black;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 1500;

    .head {
        border-bottom: 1px solid $white;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: .5rem 1rem;
        flex: 0 0 auto;
        justify-content: space-between;
    }

    .options {
        padding: 1rem;
        flex: 1 1 auto;
        max-height: calc(100vh - 45px);
        overflow-y: auto;
    }

    .placeholder {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1 1 auto;
    }

    .close {
        margin-left: 1rem;
        flex: 0 0 auto;
        position: relative;
        top: 0;
        right: 0;
    }

    .custom-radio {
        &:last-child {
            margin-bottom: 0;
        }
    }
}