﻿$cropSizes: (
    (
        'alias': 'banner',
        'width': 1920,
        'height': 636
    ),
    (
        'alias': 'mobileBanner',
        'width': 700,
        'height': 280
    ),
    (
        'alias': 'thumbnail',
        'width': 415,
        'height': 300
    ),
    (
        'alias': 'square',
        'width': 360,
        'height': 360
    ),
    (
        'alias': 'sideImage',
        'width': 880,
        'height': 647
    ),
    (
        'alias': 'carouselThumbnail',
        'width': 749,
        'height': 413
    ),
    (
        'alias': 'listIntroSideImage',
        'width': 460,
        'height': 594
    ),
    (
        'alias': 'feedDetail',
        'width': 500,
        'height': 365
    )
);
.image-container {
    position: relative;
    max-width: 100%;

    &::before {
        content: '';
        display: block;
    }

    > img, > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    > div {
        height: 100%;

        &.center {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .spacer {
        position: relative;
    }
}

@mixin buildWrapper($size) {
    @include breakpoint($size) {
        @each $crop in $cropSizes {
            $width: map-get($crop, width);
            $height: map-get($crop, height);
            $ratio: $height * 100% / $width;

            .#{$size}-image-container-#{map-get($crop, alias)} {
                width: #{$width}px;

                &::before {
                    padding-top: $ratio;
                }
            }
        }
    }
}

@include buildWrapper(small);
@include buildWrapper(medium);
@include buildWrapper(large);