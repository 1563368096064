﻿&.variant-news {
    @include breakpoint(medium) {
        .feed-items {
            margin-bottom: -4.5rem;
        }
        .feed-item {
            margin-bottom: 4.5rem;
        }
    }
}
