﻿location-search {
    width: 258px;
    background-color: $white;
    display: flex;
    height: 47px;
    align-items: center;
    max-width: 100%;
    position: relative;

    input[type='text'], i {
        color: $black;
    }

    input[type='text'], input[type='text']:focus {
        background-color: transparent;
        border-width: 0;
        box-shadow: none;
    }

    input::placeholder {
        color: inherit;
    }

    input[type='text'] {
        text-transform: uppercase;
        font-size: .875rem;
        font-weight: 600;
        flex: 1 1 auto;
        padding-left: 1.25rem;
        padding-right: 4rem;
        letter-spacing: 2px;
        margin: 0;
    }

    .fa {
        position: absolute;
        width: 60px;
        right: 0;
        top: 0;
        font-size: 1.5rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
