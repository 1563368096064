﻿.product-card {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    color: $white;
    background-color: $black;

    h6, p{
        margin: 0;
        text-align: center;
    }

    h6{
        font-size: 1rem;
        text-transform: none;
        transition: color .3s;
    }

    .image {
        display: flex;
        height: 190px;
        flex: 0 0 190px;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 2.5rem;
        &.tall-image {
            height: 334px;
        }
    }

    &:not(.product-card--with-buttons):hover, &:not(.product-card--with-buttons):focus, &--with-buttons > a:hover, &--with-buttons > a:focus {
        color: $white;
        h6 {
            color: $yellow;
        }
    }

    &--with-border {
        border: 1px solid $white;
        box-shadow: none;
        transition: box-shadow .3s;

        &:hover, &:focus {
            box-shadow: 0 0 8px $yellow;
        }
    }

    .buttons {
        display: flex;
        margin: 2.5rem #{-$smallGutters} -1rem;
        justify-content: center;
        flex-wrap: wrap;
        .button {
            min-width: 164px;
            max-width: calc(50% - #{$smallGutters * 2});
            margin: 0 #{$smallGutters} 1rem
        }
    }
}

.rte {
    a:not([class]) {
        color: $yellow;
        font-weight: 600;
    }
}

.underline-link {
    font-size: 1.875rem;
    font-weight: 700;
    display: inline-block;
    color: $white;
    opacity: .7;

    &::after {
        content: '';
        height: .25rem;
        background-color: $yellow;
        width: 0;
        display: block;
        transition: all .3s;
    }

    &.active, &:hover {
        opacity: 1;
        color: $white;

        &::after {
            width: 100%;
        }
    }
}

.icon-link {
    display: inline-flex;
    text-transform: uppercase;
    font-weight: 600;
    color: $white;
    align-items: center;
    font-size: 1rem;

    > i, &::before {
        color: $yellow;
        display: inline-block;
        width: 25px;
        margin-right: 1.5rem;
        font-size: 1.5rem;
        text-align: center;
    }

    &:hover, &:focus {
        color: $yellow;
    }
}

span.icon-link {
    &:hover, &:focus {
        color: $white;
    }
}

.light-background {
    h1,h2,h3,h4,h5,h6 {
        color: $black;
    }

    .icon-link {
        color: $black;
        &:hover {
            color: $yellow;
        }
    }
}