@import '_variables.scss';

@import 'fonts/*.scss';
@import 'motion-ui/motion-ui.scss';
@import 'vendors/_foundation.scss';

[id] {
    scroll-margin-top: 70px;
}

#sc8RK1 {
    z-index: 50 !important;
    right: 10px !important;
    bottom: 10px !important;
}

.has-recaptcha {
    #sc8RK1 {
        right: 5px !important;
        bottom: 80px !important;
    }
}

@include breakpoint(medium) {
    [id] {
        scroll-margin-top: 161px;
    }
}
@include motion-ui-transitions;
@include motion-ui-animations;
@import 'icons/*.scss';
@import '../angular/components/carousel/_carousel.scss';
@import '../angular/components/sticky/_sticky.scss';
@import 'common/forms/_locationSearch.scss';
@import '../App_Plugins/Feed/_styles.scss';
@import 'common/_cookieConsent.scss';
@import 'common/_iconList.scss';
@import 'common/_stars.scss';
@import 'common/_revealer.scss';
@import 'common/forms/_forms.scss';
@import 'common/_text.scss';
@import 'common/_links.scss';
@import 'common/_grid.scss';
@import 'common/_imageContainers.scss';
@import 'common/_inlineList.scss';
@import 'common/accordion/_accordions.scss';
@import 'common/_shifter.scss';
@import 'common/_suggestor.scss';
@import 'common/_header.scss';
@import 'components/_banner.scss';
@import 'components/feeds/_feeds.scss';
@import 'components/intros/_intros.scss';
@import 'components/_breadcrumb.scss';
@import 'components/_search.scss';
@import 'components/_dealerSearch.scss';
@import 'components/_documentSearch.scss';