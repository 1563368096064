﻿.widget-dealer-search {

    .filters {
        margin-bottom: 3.25rem;

        h5 {
            font-size: 1rem;
            text-transform: none;
        }
    }

    .map-container {
        position: relative;
        max-height: calc(100vh - #{rem-calc($mobileHeaderHeight) + 2.5rem});
        overflow: hidden;

        &::before {
            padding-top: 100%;
            content: '';
            display: block;
        }
    }

    .buttons {
        display: flex;
        margin: 0 -.5rem 1.25rem;
        flex-wrap: wrap;

        > div {
            margin: 0 .5rem 1rem;
            flex: 0 0 calc(50% - 1rem);
        }

        label {
            width: 100%;
        }
    }

    select, location-search, checkbox-dropdown {
        width: 100%;
        margin-bottom: 2.25rem;
    }

    .top-count {
        border-bottom: 2px solid $white;
        padding-bottom: 2.25rem;
        text-transform: none;
        font-size: 1.125rem;
    }

    google-map {
        z-index: 2;
    }

    select.active, checkbox-dropdown.active .placeholder {
        border-color: $yellow;
        color: $yellow;
    }

    select.active {
        option {
            color: $white;
        }
    }

    .pagination-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        h5 {
            margin-bottom: 0;
        }

        select {
            margin: 0 1.5rem 0 0;
            padding-right: 1.75rem;
            width: 70px;
            color: $yellow;
            height: 30px;

            &, &:focus {
                background-color: $black;
            }
        }

        a {
            color: $white;

            &:hover {
                color: $yellow;
            }
        }

        .select-block, a {
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1.5px;
        }

        .select-block {
            margin: 0 2.5rem;
            display: inline-flex;
            align-items: center;
        }

        .ng-hide + .select-block {
            margin: 0;
        }

        .disabled {
            visibility: hidden;
            pointer-events: none;
        }

        h5 {
            font-size: 1.125rem;
        }
    }

    .submit-wrapper {
        position: sticky;
        bottom: 0;
        background-image: linear-gradient(to bottom, transparent 0rem, $black 1rem);
        padding-top: 1rem;
        margin-top: -1rem;
    }

    .hidden {
        visibility: hidden;
    }

    $dealerWidth: rem-calc(1327px);

    @include breakpoint(medium down) {
        .map-column {
            flex: 0 0 100%;
            margin-bottom: 1rem;
        }

        .filters, .filter-background {
            position: fixed;
            top: 70px;
            height: calc(100vh - 70px);
        }

        .close, .open {
            color: $white;

            &:hover {
                color: $yellow;
            }
        }

        .open {
            font-size: 1.25rem;
            display: inline-block;
            margin-bottom: .75rem;
            text-transform: uppercase;

            i {
                margin-right: .5rem;
            }
        }

        .close {
            position: absolute;
            top: .5rem;
            right: $smallGutters;

            + .filter-block {
                padding-top: .375rem;
            }
        }

        .filters {
            width: rem-calc(417) + (2 * $mediumGutters);
            max-width: 100%;
            right: 0;
            transform: translateX(100%);
            transition: transform .5s;
            z-index: 4;
            background-color: $black;
            display: flex;
            flex-direction: column;
            padding: $mediumGutters;
            overflow: auto;

            > h5 {
                padding: .5rem 2.5rem 0 0;
                margin: 0 $smallGutters;
            }
        }

        .filter-background {
            background-color: rgba($black, .5);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            z-index: 3;
            transition: .5s all;

            &:hover {
                cursor: pointer;
            }
        }

        .filters-open {
            .filters {
                transform: translateX(0);
            }

            .filter-background {
                visibility: visible;
                opacity: 1;
                pointer-events: inherit;
            }
        }
    }

    @include breakpoint(large) {
        .wrapper {
            display: flex;
            margin: 0 #{-$mediumGutters};
            justify-content: space-between;
            flex-wrap: nowrap;

            > div {
                margin: 0 #{$mediumGutters};
            }

            > .content {
                width: 417px;
                flex: 0 0 417px;
            }
        }

        .map-column {
            flex: 0 1 776px;
            position: relative;
        }

        .map-container {
            max-height: calc(100vh - #{rem-calc($desktopHeaderHeight) + 2.5rem});
        }
    }

    @media print, screen and (min-width: #{$grid-container + 2 * $mediumGutters}) {
        width: calc(50vw + #{$grid-container / 2 - 3 * $mediumGutters});
        position: relative;
        left: calc(#{$grid-container / 2 + $mediumGutters} - 50vw);
    }

    @media print, screen and (min-width: #{2 * $dealerWidth - $grid-container + 6 * $mediumGutters}) {
        left: $grid-container - $dealerWidth - 2 * $mediumGutters;
        width: $dealerWidth;
    }

    @media (max-width:1023px) {
        .mobile-form-scroll {
            max-height: calc(100vh - 190px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 5px;
            margin: 27px -5px 15px 0;
        }

        .widget-dealer-search {
            .submit-wrapper {
                padding-top: 10px;
                margin-top: 0;
            }
        }
    }
}
