﻿
carousel-sync {
    display: block;
}
carousel, carousel-v2 {
    height: auto;
    max-width: 100%;
    display: block;

    @include breakpoint(medium down) {
        min-height: inherit !important;
    }

    .drag-tracker {
        position: absolute;
    }

    > div {
        white-space: nowrap;
        position: relative;
        height: 100%;
        margin: 0;
        display: block;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .slider {
        flex: 1 1 auto;
        position: relative;
        overflow: hidden;
        padding: 0;

        > .loader {
            font-size: 3em;
            right: 15px;
        }
    }

    .slides {
        overflow: hidden;
        position: relative;
        height: auto;
        width: 100%;
        top: 0;
        left: 0;

        > div {
            /*width: 100%;
            height: 100%;*/
            white-space: nowrap;
            text-align: center;
        }
    }

    .slide {
        white-space: normal;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        text-align: left;
        width: 100%;
        height: auto;
        position: relative;
        backface-visibility: hidden;

        .content {
            margin: 0 auto;
            white-space: normal;
            position: relative;
            max-height: 100%;
            max-width: 100%;
            padding: 1em;
            text-align: left;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .pagination {
            .empty {
                text-align: center;
            }
        }
    }

    .controls {
        .prev, .next {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .prev {
            left: 0;
        }

        .next {
            right: 0;
        }
    }
}

/*$carouselNavDefault: $secondaryColor !default;
$carouselNavActive: red !default;

carousel-nav {
    a {
        display: inline-block;
        background-color: $carouselNavDefault;
        width: 10px;
        height: 10px;
        vertical-align: middle;
        transition: background-color .3s;
        + a{
            margin-left: 5px;
        }
        &.active, &:hover {
            background-color: $carouselNavActive;
        }
    }
}*/

carousel-set {
    .set {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        perspective: 1000px;
        height: 50px;

        > li {
            display: inline-block;

            &:first-child {
                margin-right: 2%;
            }

            + li {
                margin: 0 2%;
            }

            &:last-of-type {
                margin: 0 0 0 2%;
            }
        }
    }
}
