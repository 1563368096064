﻿.widget-banner {
    .video {
        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .links {
        position: relative;
        background-image: url('/media/akbcwstv/gray_paper_strip_print.jpg'), url('/media/akbcwstv/gray_paper_strip_print.jpg'), url('/media/akbcwstv/gray_paper_strip_print.jpg'), url('/media/akbcwstv/gray_paper_strip_print.jpg');
        background-blend-mode: multiply;
        padding: 1rem 0;
        background-color: $yellow;

        &.to-border {
            padding-bottom: 0;
        }

        ul {
            list-style: none;
            margin-bottom: -1rem;
        }

        li {
            margin-bottom: 1rem;
        }
    }

    .image-link-carousel {

        .controls {
            top: 50%;
            position: absolute;
            width: 100%;
            margin-top: -1.25rem;
            z-index: 2;

            a {
                font-size: 2.5rem;
                color: $blue;

                &:hover, &:focus {
                    color: $black;
                }
            }

            .next {
                right: .5rem;
            }

            .prev {
                left: .5rem;
            }
        }
    }

    .grid-container {
        width: 100%;
    }

    &.variant-video {
        .outline-button {
            width: 100%;
        }
    }

    &.variant-basic {
        .links {
            padding-left: $smallGutters;
            padding-right: $smallGutters;

            .inline-list {
                justify-content: center;
            }

            .button {
                min-width: 231px;
            }
        }
    }

    .background {
        > div {
            background-size: cover;
            background-position: center;
        }

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: $black;
            opacity: .3;
            z-index: 1;
        }
    }

    @include breakpoint(small only) {
        .fill-container {
            padding: 1.5rem 0;
        }
    }

    @include breakpoint(medium) {

        .fill-container {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
        }

        .rte {
            max-width: 800px;
        }

        .video {
            visibility: hidden;

            iframe {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .links {
            flex: 0 0 auto;

            &:not(.to-border) {
                padding: 2.5rem 0;
            }
        }

        &.variant-video {

            .rte {
                max-width: 945px;
            }

            height: calc(100vh - 70px);
            display: flex;
            flex-direction: column;
        }

        &.variant-basic {
            .links {
                padding-left: $mediumGutters;
                padding-right: $mediumGutters;
            }

            .fill-container {
                min-height: 400px;
            }
        }

        .image-link-carousel {
            padding: 0 6.625rem;

            .controls {
                .next {
                    right: -5rem;
                }

                .prev {
                    left: -5rem;
                }
            }
        }
    }

    @include breakpoint(large) {
        &.variant-video {
            height: calc(100vh - 30px);
        }
    }
}
