﻿$pd-day-hover-bg: $yellow;
$pd-text-color: $black;
$pd-title-color: $black;
$pd-day-color: $black;
$pd-day-hover-color: $black;
@import '../node_modules/pikaday/scss/pikaday.scss';
.datepicker {
    &[readonly] {
        background-color: $white;
        &:hover {
            cursor: pointer;
        }
    }
}
.datepicker-container {
    position: relative;
    top: -1rem;
    width: 100%;
    max-width: 256px;

    .pika-single {
        position: absolute !important;
    }
}
﻿product-selector {
    display: block;
    max-width: 729px;
    margin-bottom: -1.5rem;

    .cell {
        margin-bottom: 1.5rem;
    }

    textarea, ul {
        min-height: 161px;
        margin-bottom: 1rem;
    }

    ul {
        border: 1px solid $grey;
        margin-left: 0;
        padding: .625rem 1.25rem;
        font-size: .875rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    .button {
        width: 200px;
    }

    @include breakpoint(medium) {
        .inputs {
            justify-content: space-between;
        }

        .cell {
            display: flex;
            flex-direction: column;
            max-width: 300px;
        }

        input, select, textarea {
            width: 100%;
        }

        textarea, ul {
            flex: 1 1 auto;
        }
    }
}

﻿.review-field {
    max-width: 700px;
    text-align: left;

    > .multi-column {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 #{-$smallGutters} -1rem;
        > div {
            margin: 0 #{$smallGutters} 1rem;
        }
    }
    .left {
        width: 338px;
    }

    .right {
        width: 175px;
    }

    p {
        border: 1px solid #000;
        padding: .75rem 1rem .6875rem;
        font-size: .875rem;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 1.0625rem;
        min-height: 47px;
        letter-spacing: 2px;
        margin-bottom: 1rem;
    }

    h6 {
        margin-bottom: 0;
    }

    @include breakpoint(medium) {
        > .multi-column {
            margin: 0 #{-$mediumGutters};
            > div {
                margin: 0 #{$mediumGutters};
            }
        }
    }
}