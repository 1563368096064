﻿.inline-list {
    display: flex;
    padding: 0;
    list-style: none;
    margin: 0 -.5rem;
    flex-wrap: wrap;

    > li {
        margin: 0 .5rem;
    }
    &--large-gap {
        margin: 0 -1rem;

        > li {
            margin: 0 1rem;
        }
    }
}
