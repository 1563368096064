﻿.widget-breadcrumb {
    .inline-list {
        margin: 0 -.25rem;
    }

    li {
        margin: 0 .25rem;
        &:last-child {
            color: $yellow;
        }
    }

    a{
        color: $white;
        &:hover {
            color: $yellow;
        }
    }
}