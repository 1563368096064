﻿.widget-search {
    .search {
        background-color: transparent;
        border-bottom: 1px solid $white;
        width: 600px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        height: 3.4375rem;

        input {
            font-size: 1.875rem;
            font-weight: 700;
            text-transform: none;
            color: $white;
            height: 3.4375rem;
            padding-left: 0;
        }

        button {
            color: $white;
            padding-left: 1.5rem;
            flex: 0 0 auto;

            i.fa-times {
                font-size: 12px;
                display: block;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .top-count {
        font-size: 1.5rem;
        font-weight: 500;
        text-transform: none;
        margin-bottom: 2.5rem;
        border-bottom: 1px solid $white;
    }

    .search-pagination {
        .top-count {
            border-bottom: 0;
        }
    }

    .search-pagination {
        border-bottom: 1px solid $white;
        padding-bottom: 1.75rem;
    }

    .pagination-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        h5 {
            margin-bottom: 0;
        }

        select {
            margin: 0 1.5rem 0 0;
            padding-right: 1.75rem;
            width: 70px;
            color: $yellow;
            height: 30px;

            &, &:focus {
                background-color: $black;
            }
        }

        a {
            color: $white;

            &:hover {
                color: $yellow;
            }
        }

        .select-block, a {
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1.5px;
        }

        .select-block {
            margin: 0 2.5rem;
            display: inline-flex;
            align-items: center;
        }

        .ng-hide + .select-block {
            margin: 0;
        }

        .disabled {
            visibility: hidden;
            pointer-events: none;
        }
    }

    .filter {
        > h5 {
            font-size: 1.125rem;
            color: $yellow;
            margin-bottom: 1.25rem;
        }
    }

    .filters {
        label {
            font-size: 1rem;
        }
    }

    .search-result {
        padding-bottom: 2.5rem;
        margin-bottom: 2.5rem;
        border-bottom: 1px solid $white;

        h4 {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
            text-transform: none;
        }

        h6 {
            color: $yellow;
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }

        img {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        a {
            color: $white;
        }
    }

    .icon-container {
        width: 92px;
        height: 92px;
        left: 50%;
        top: 50%;
        margin-left: -46px;
        margin-top: -46px;
        border: 1px solid $white;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 2.5rem;
            color: $yellow;
        }
    }

    .learn-more {
        color: $yellow;
    }

    @include breakpoint(medium down) {

        .pagination-controls {
            h5 {
                margin-bottom: 1rem;
            }

            .select-block {
                margin: 0 1.5rem;
            }
        }

        .bottom-controls {
            h5 {
                margin-bottom: 0;
            }
        }

        .filters, .filter-background {
            position: fixed;
            top: 70px;
            height: calc(100vh - 70px);
        }

        .close, .open {
            color: $white;

            &:hover {
                color: $yellow;
            }
        }

        .open {
            font-size: 1.25rem;
            display: inline-block;
            margin-bottom: 2.5rem;
            text-transform: uppercase;

            i {
                margin-right: .5rem;
            }
        }

        .close {
            font-size: 2.5rem;
            position: absolute;
            top: 0;
            right: $smallGutters;

            + .filter-block {
                padding-top: .375rem;
            }
        }

        .close-button {
            top: 1.0625rem;
        }

        .filters {
            width: rem-calc(258);
            right: 0;
            transform: translateX(258px);
            transition: transform .5s;
            z-index: 4;
            background-color: $black;
            display: flex;
            flex-direction: column;

            > h5 {
                padding: .5rem 2.5rem 0 0;
                margin: 0 $smallGutters;
            }
        }

        .filter-block {
            flex: 1 1 auto;
            overflow: auto;
            padding: 1rem $smallGutters;

            &:first-child {
            }
        }

        .filter-background {
            background-color: rgba($black, .5);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            z-index: 3;
            transition: .5s all;

            &:hover {
                cursor: pointer;
            }
        }

        .filters-open {
            .filters {
                transform: translateX(0);
            }

            .filter-background {
                visibility: visible;
                opacity: 1;
                pointer-events: inherit;
            }
        }
    }

    @include breakpoint(medium) {

        .search-result {
            a {
                display: flex;
            }
        }

        .image-container {
            width: 160px;
            flex: 0 0 auto;
            margin-right: 2.5rem;

            > img {
                width: auto;
                max-height: 100%;
            }
        }
    }

    @include breakpoint(large) {

        .filters {
            margin-bottom: 3.5rem;
        }

        .filter-block {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1rem -2.5rem;
        }

        .filter {
            margin: 0 1rem 2.5rem;

            select {
                margin-bottom: 0;
            }
        }

        .type-divider {
            display: flex;
            align-items: center;

            h5 {
                margin-bottom: 0;
            }
        }

        .type-checkbox, .type-page-types {
            h5 {
                margin-bottom: 2rem;
            }
        }

        .type-include-discontinued {
            flex: 0 0 100%;
        }

        .type-sort {
            flex: 0 1 auto;

            select {
                width: 159px;
            }
        }

        multi-checkbox {
            display: flex;
            margin: 0 -#{$mediumGutters} -1rem;

            > div {
                margin: 0 #{$mediumGutters} 1rem;
            }
        }

        &.variant-site {
            .filter-block {
                justify-content: space-between;
            }
        }

        .lead-in {
            display: flex;
            justify-content: space-between;
            margin: 0 -1rem;

            > div {
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }
}

div#video-popup {
    position: fixed;
    z-index: 999;
    opacity: 1;
    height: 100%;
    width: 100%;
    top: 0 !important;
    left: 0 !important;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;


    .reveal {
        padding: 1rem 2.5rem 2.5rem;
        width: 75rem;
        opacity: 1;
        background: #fff;
        display: block;
        height: auto;
        position: static;
        text-align: right;
        max-width: 100%;
        min-height: auto;
    }


    .reveal .message {
        padding-top: 56.25%;
        position: relative;
    }

    .reveal .message #video-iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .reveal .close-button {
        position: static;
        margin-bottom: 1rem;
        display: inline-block;
    }

    iframe {
        height: 100%;
        width: 100%;
    }
}

@media(max-width:639px) {
    div#video-popup {
        padding: 10px;

        .reveal .close-button {
            margin-bottom: 12px;
        }

        .reveal {
            padding: 1rem 1.4rem 1.4rem;
        }
    }
}
