﻿$toggleIconColor: #000 !default;
$toggleIconActiveColor: #FFF !default;

.toggle-icon {
    display: inline-block;
    width: 1em;
    height: .625em;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: .125em;
        width: 50%;
        background: $toggleIconColor;
        opacity: 1;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(even) {
            left: 50%;
        }

        &:nth-child(odd) {
            left: 0;
        }

        &:nth-child(1), &:nth-child(2) {
            top: 0;
        }

        &:nth-child(3), &:nth-child(4) {
            top: .3em;
        }

        &:nth-child(5), &:nth-child(6) {
            top: .6em;
        }
    }
}

.menu-open {
    .toggle-icon {
        span {
            background: $toggleIconActiveColor;

            &:nth-child(1), &:nth-child(6) {
                transform: rotate(45deg);
            }

            &:nth-child(2), &:nth-child(5) {
                transform: rotate(-45deg);
            }

            &:nth-child(1) {
                left: .083333em;
                top: .116667em;
            }

            &:nth-child(2) {
                left: calc(50% - .083333em);
                top: .116667em;
            }

            &:nth-child(3) {
                left: -50%;
                opacity: 0;
            }

            &:nth-child(4) {
                left: 100%;
                opacity: 0;
            }

            &:nth-child(5) {
                left: .083333em;
                top: .483333em;
            }

            &:nth-child(6) {
                left: calc(50% - .083333em);
                top: .483333em;
            }
        }
    }
}