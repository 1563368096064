﻿.feed {
    .see-more {
        margin-top: 1rem;

        @include breakpoint(medium) {
            margin-top: 3rem;
        }
    }

    .feed-loader {
        position: relative;
        text-align: center;
        height: auto;
        height: 70px;
    }

    .bottom-copy {
        .mdl-button {
            margin-left: 1rem;
        }
    }
}

.feed-list {
    list-style-type: none;

    .inline-title {
        order: 2;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        visibility: hidden;
        transition: 1s opacity, 1s visibility;

        &.positioned {
            visibility: visible;
            opacity: 1;
        }
    }

    .feed-item {
        order: 1;

        @include breakpoint(small only) {
            &.small-end {
                order: 3;
            }
        }

        @include breakpoint(medium only) {
            &.medium-end {
                order: 3;
            }
        }

        @include breakpoint(large) {
            &.large-end {
                order: 3;
            }
        }
    }
}

.feed-modal {
    position: fixed;
    background-color: rgba($blue,.8);
    z-index: 10000;
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s, opacity .3s;

    &:not(.open) {
        .wow {
            visibility: hidden !important;
        }
    }

    &.open {
        visibility: visible;
        opacity: 1;
    }

    &, .modal-background {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .modal-background {
        position: absolute;
        cursor: pointer;
    }

    carousel {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }

    .slides {
        height: 100%;
    }

    .slide, .slide-background {
        width: 100%;
        height: 100vh;
    }

    .slide-background {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .slide-wrapper {
        width: 75rem;
        max-width: 100%;
        max-height: 100%;
        cursor: move;
        position: relative;

        .close {
            font-size: 2em;
            position: absolute;
            right: .25em;
            top: .25em;
            z-index: 2;
        }

        &.cap-height {
            height: 100%;
        }
    }

    .lower-close {
        margin-top: 1rem;
    }

    .feed-scroll {
        max-height: 100%;
        overflow-y: auto;
        touch-action: pan-y;
    }
}