﻿.shifter {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .shifter-navigation {
        display: flex;
        justify-content: space-between;
        flex: 0 0 auto;

        a {
            visibility: hidden;
            pointer-events: none;
        }
    }

    .shifter-navigation {
        border-bottom: 2px solid $white;
        padding-bottom: .5rem;
        margin-bottom: .5rem;
    }

    .shift-block {
        flex: 0 1 auto;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        height: calc(100% - 42px);
    }

    ul {
        list-style-type: none;
        margin: 0;
    }

    .has-children {
        &::after {
            margin-left: .5rem;
            content: '\f011';
            font-family: Legends;
        }
    }


    .shifter-group {
        flex: 0 0 100%;
        display: none;
        overflow-x: hidden;
        overflow-y: auto;

        + .shifter-group {
            margin-left: 1rem;
        }

        &.active {
            display: block;
        }
    }
}
