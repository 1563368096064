﻿.umbraco-forms-page {
    .custom-radio {
        label {
            text-transform: none;
            font-weight: normal;
            font-size: 1.125rem;
        }

        a {
            color: $white;

            &:hover {
                color: $yellow;
            }
        }
    }

    .button--previous {
        margin-right: 1.25rem;
    }

    input, textarea {
        padding: 0 2.5rem 0 1.25rem;

        &::placeholder {
            color: $black;
            font-weight: 600;
            letter-spacing: 0.125rem;
        }
    }

    input {
        height: 47px;
    }

    input, textarea {
        padding: 0 1.25rem 0 1.25rem;
    }

    select {
        width: 100%;
    }

    textarea {
        min-height: 9.375rem;
        padding-top: .625rem;
        padding-bottom: .625rem;
    }

    option[disabled] {
        display: none;
    }

    .field-validation-error {
        margin-top: -1rem;
        margin-bottom: 1rem;
        display: block;
        color: $red;
    }
}

.hbspt-form {
    .inputs-list {
        list-style-type: none;
        margin: 0;
    }

    .hs-error-msgs, .hs_recaptcha {
        margin-bottom: 1.25rem;
    }

    input, textarea {
        padding: 0 1.25rem 0 1.25rem;

        &::placeholder {
            color: $black;
            font-weight: 600;
            letter-spacing: 0.125rem;
        }
    }

    textarea {
        min-height: 9.375rem;
        padding-top: .625rem;
        padding-bottom: .625rem;
    }

    input {
        height: 47px;
    }
}

.hs-fieldtype-select {
    select {
        width: 100%;
    }
}

.hs-error-msg {
    color: $red;
    font-size: 1rem;
}

.light-background {
    input, select, textarea {
        border-color: $black;
    }

    legend {
        color: $black;
        font-weight: bold;
        font-size: 1.125rem;
    }

    .custom-radio {
        a {
            color: $yellow;

            &:hover {
                color: darken($yellow, 10%);
            }
        }
    }
}

.form-step {
    .umbraco-forms-navigation {
        margin-top: 2.5rem;
        margin-bottom: -1rem;

        button {
            margin-bottom: 1rem;
        }
    }

    @include breakpoint(medium) {
        .umbraco-forms-navigation {
            margin-top: 4.5rem;
        }
    }
}
