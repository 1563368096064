﻿@import '../_variables.scss';
@import 'foundation-sites/scss/foundation.scss';
body {
    color: $white;
    font-family: $barlow;
}

.rte {
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    color: $white;
    font-family: $barlow;

    strong {
        font-weight: 700;
    }

    a{
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }
    .emphasize {
        color: $yellow;
    }
}

h1, .h1 {
    font-family: $barlowCondensed;
    font-weight: 700;
    font-size: 2.5rem;
    letter-spacing: .125rem;
    line-height: 1.075;
    margin-bottom: 3rem;
    text-transform: uppercase;

    @include breakpoint(large) {
        font-size: 5rem;
        letter-spacing: .25rem;
    }
}

h2, .h2 {
    font-family: $barlowCondensed;
    font-size: 5.625rem;
    line-height: .9;
    letter-spacing: 0;
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    font-weight: 700;
}

h3, .h3 {
    font-family: $barlowCondensed;
    font-size: 3.75rem;
    line-height: 4.125rem;
    letter-spacing: 0;
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 3px;
}

h4, .h4 {
    font-size: 2.25rem;
    line-height: 2.875rem;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 3rem;
}

.h4-5 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    color: $yellow;
    letter-spacing: .25px;
    text-transform: none;
    margin-bottom: 2.25rem;
}

h5, .h5 {
    font-size: 1.25rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 2rem;
}

h6, .h6 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-bottom: 1rem;
}

ul, ol, p {
    font-size: 1.125rem;
    margin-bottom: 2.5rem;
    .emphasize {
        color: $yellow;
        font-weight: 600;
    }
    &.large-text {
        font-size: 1.5rem;
        font-weight: 600;
    }
}

ul, ol {
    p {
        font-size: inherit;
        font-weight: inherit;
    }
}

pre {
    max-width: 100%;
    overflow: auto;
}

a {
    transition: color $defaultAnimationSpeed;
}

i.fa {
    font-style: normal;
}

.red-text {
    color: $red;
}

.blue-text {
    color: $blue;
}

.green-text {
    color: $green;
}

.yellow-text {
    color: $yellow;
}

.white-text {
    color: $white;
}

/*.large-text {
    font-family: $barlow;
    font-size: 1.25rem;
    line-height: 1.875rem;
    letter-spacing: 0.5px;

    a {
        font-size: inherit;
    }
}*/

/*.medium-text {
    font-size: 20px;
    line-height: 1.4;

    a {
        font-size: inherit;
    }
}

.larger-text {
    font-size: 30px;
    line-height: 1.4;
    a{
        font-size: inherit;
    }
}*/

.no-margin {
    margin: 0 !important;
}

.large-margin, .underline {
    margin-bottom: 3rem;
}

.underline {
    padding-bottom: 1.5rem;
    border-bottom: 2px solid $light-gray;
}

.line-separated {
    list-style: none;
    margin-left: 0;
    li + li {
        margin-top: 1.375rem;
        padding-top: 1.375rem;
        border-top: 1px solid $white;
    }
}

.light-background {
    .line-separated {
        border-color: $black;
    }
}

@include breakpoint (small only) {
    h2, .h2 {
        font-family: $barlowCondensed;
        font-size: 3rem;
        margin-bottom: 1.5rem;
    }

    h3, .h3 {
        font-size: 2.5rem;
        line-height: 3.625rem;
        margin-bottom: 1.25rem;
    }

    h4, .h4 {
        font-size: 1.875rem;
        line-height: 2.25rem;
        margin-bottom: 1.5rem;
    }

    .h4-5 {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 2rem;
    }

    .large-text {
        font-size: 1.25rem;
    }
}